import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import Cardcategoryvaccine from "components/Cards/cardcategoryvaccine";
import { getCategory, getCategoriesVaccine } from "../../util/index";
import CardTableNew from "components/Cards/CardTableNew";
import CardEditCategoryVaccine from "../../components/Update/CardEditCategoryVaccine";

const Categoryvaccine = () => {
  const [data, setData] = React.useState({});
  const [categoryvaccine, setCategoryvaccine] = useState();
  const [editCard, setEditcard] = useState();

  const fetchData = async () => {
    const resdata = await getCategory();
    setData(resdata);
  };

  const handleEditCard = (row) => {
    setEditcard(row);
  };
  //GET CATEGORIESVACCINE
  const fetch = async () => {
    const { result } = await getCategoriesVaccine();
    if (result) {
      setCategoryvaccine(result);
    }
  };

  React.useEffect(() => {
    fetchData();
    fetch();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="flex flex-wrap">
        {editCard && editCard.id ? (
          <div>
            <CardEditCategoryVaccine
              handleEditCard={handleEditCard}
              fetch={fetch}
              edititem={editCard}
              catvacdata={data}
            />
          </div>
        ) : (
          <div className="w-full lg:w-12/12 px-4">
            <Cardcategoryvaccine fetch={fetch} categories={data} />
          </div>
        )}
        <div className="w-full mb-12 px-4">
          <CardTableNew
            fetch={fetch}
            title="CategoryVaccine"
            handleEditCard={setEditcard}
            dropdownhidden={true}
            cols={["Category", "Vaccines", "Location"]}
            data={categoryvaccine}
            isCategoryVaccine={true}
          />
        </div>
      </div>
    </>
  );
};

export default Categoryvaccine;
