//IMPORT REACT
import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "../../api";
import { ToastContainer, toast } from "react-toastify";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { validateData, validateEditor } from "../../util";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

export default function EditCategory({ category, fetch, setEditcard }) {
  const [data, setData] = useState({});
  const [validateErr, setValidateErr] = useState({});
  const [editordata, setEditorData] = useState();
  const [isTrending] = useState(false);
  let formref = useRef(null);
  useEffect(() => {
    var elem = formref.current.getBoundingClientRect();
    window.scroll({
      top: window.scrollY + elem.y - formref.current.offsetTop,
      behavior: "smooth",
    });
  });

  useEffect(() => {
    if (category.title) {
      data.title = category.title;
      data.id = category.id;
      const contentBlock = htmlToDraft(category.description);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorData(editorState);
      }

      setData((prev) => {
        return { ...data };
      });
    }
  }, [category]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditorChange = (editorState) => {
    setEditorData(editorState);
  };

  const isValid = (htmldata) => {
    var strippedHtml = htmldata.replace(/<[^>]+>/g, "");
    let valid = true;
    if (
      validateData("title", data?.title, validateErr, setValidateErr) === false
    ) {
      valid = false;
    }
    if (
      validateEditor("editor", strippedHtml, validateErr, setValidateErr) ===
      false
    ) {
      valid = false;
    }
    return valid;
  };

  const handleFetch = useCallback(() => {
    fetch();
  }, [fetch]);

  const setEditorReference = useCallback((ref) => {
    ref?.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let htmldata = draftToHtml(convertToRaw(editordata.getCurrentContent()));
    let validateData = isValid(htmldata);
    console.log(data);

    if (validateData) {
      try {
        const res = await axios.post(`category/create`, {
          title: data.title,
          description: htmldata,
          id: data.id,
          ismain: isTrending,
        });
        if (res.status === 201) {
          toast.success("Data Updated Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setEditcard({});
          return handleFetch();
        }
      } catch (err) {
        toast.error("Failed To Update", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Edit Category
            </h6>
          </div>
        </div>

        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={(e) => handleSubmit(e)} ref={formref}>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Title
                  </label>
                  <input
                    maxLength="155"
                    value={data?.title || ""}
                    name="title"
                    onChange={handleChange}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
                {validateErr && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {validateErr?.title}
                  </small>
                )}
              </div>
              <div className="w-full lg:w-6/12 px-4"></div>

              <div className="text-center flex justify-between">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  &nbsp; &nbsp; &nbsp;Description
                </label>
              </div>
              <div
                className="w-full lg:w-full px-4"
                style={{
                  height: "200px",
                  width: "100%",
                  marginTop: "8px",
                  padding: "1px",
                  border: "solid",
                  borderWidth: "0.2px",
                  borderColor: "grey",
                }}
              >
                <Editor
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  editorState={editordata}
                  onEditorStateChange={handleEditorChange}
                  editorRef={setEditorReference}
                />
              </div>
              {validateErr && (
                <small
                  style={{ color: "red", fontSize: "14px", marginLeft: "24px" }}
                >
                  {validateErr?.editor}
                </small>
              )}
            </div>
            <button
              type="submit"
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 mb-3 mt-4 ease-linear transition-all duration-150"
            >
              Submit
            </button>
            <button
              className="bg-red-500 text-white active:bg-red-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
              onClick={(e) => {
                e.preventDefault();
                setEditcard({});
              }}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
