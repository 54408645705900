//IMPORT REACT
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../api";
import { validateData, validateEmail } from "../../util";

export default function ResetEmail() {
  const [data, setData] = useState();
  const [validateErr, setValidateErr] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    if (name === "email" && value.length > 0) {
      if (validateEmail(value) === false) {
        validateErr.email = "Invalid Email";
        setValidateErr({ ...validateErr });
        return;
      }
    }
    validateData(name, value, validateErr, setValidateErr);
  };
  const cleanAllField = () => {
    setData({});
  };
  //VALIDATION
  const isValid = () => {
    let valid = true;
    if (
      validateData("email", data?.email, validateErr, setValidateErr) === false
    ) {
      valid = false;
    }
    return valid;
  };
  //API CALL
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validateData = isValid(data);
    if (validateData) {
      try {
        const res = await axios.post("/auth/reset", {
          email: data.email,
        });
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          cleanAllField();
        }
      } catch (err) {
        toast.error(err.response.data.message || "Something went wrong", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">Email</h6>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form onSubmit={handleSubmit}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      name="email"
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      onChange={handleChange}
                      value={data?.email || ""}
                    />
                    {validateErr && (
                      <small style={{ color: "red", fontSize: "14px" }}>
                        {validateErr?.email}
                      </small>
                    )}
                  </div>
                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
