import React, { useEffect, useState } from "react";
import { getCategories } from "../../util/index";
import CardEditCategory from "components/Update/CardEditCategory";
import CardTable from "components/Cards/CardTableNew";
import CardAddCategory from "components/Cards/CardAddCategory";

export default function Category() {
  const [editCard, setEditcard] = useState({});
  const [category, setCategory] = useState();

  useEffect(() => {
    fetch();
  }, []);

  //GET CATEGORIES
  const fetch = async () => {
    const categories = await getCategories();
    if (categories.categories) setCategory(categories.categories);
  };
  return (
    <>
      <div className="flex flex-wrap">
        {editCard && editCard.id ? (
          <div className="w-full lg:w-12/12 px-4">
            <CardEditCategory
              fetch={fetch}
              category={editCard}
              setEditcard={setEditcard}
            />
          </div>
        ) : (
          <div className="w-full lg:w-12/12 px-4">
            <CardAddCategory fetch={fetch} />
          </div>
        )}
        <div className="w-full mb-12 px-4">
          <CardTable
            fetch={fetch}
            title="Category"
            handleEditCard={setEditcard}
            cols={["Title", "Description"]}
            data={category}
            isCategory={true}
          />
        </div>
      </div>
    </>
  );
}
