//IMPORT REACT
import React, { useState } from "react";
import axios from "../../api";

export default function CardActivity() {
  //SET STATES
  const [data, setData] = useState();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };
  //API CALL
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`/activity/create`, {
        location: data.location,
        name: data.activityname,
        description: data.description,
        photos: "photos",
      });
      if (res.status === 200) {
        return res;
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Add Activity
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-2">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Select Location
                </label>
                <div className="relative w-full mb-3">
                  <select
                    onChange={handleChange}
                    value={data?.location || ""}
                    name="location"
                    className="border-0 px-2 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  >
                    <option defaultValue=""></option>
                    <option value="1">Location1</option>
                    <option value="2">Location2</option>
                    <option value="3">Location3</option>
                  </select>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Activity name
                  </label>
                  <input
                    value={data?.activityname || ""}
                    name="activityname"
                    onChange={handleChange}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Description
                  </label>
                  <input
                    value={data?.description || ""}
                    onChange={handleChange}
                    name="description"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Images
                  </label>
                  <input
                    onChange={handleChange}
                    value={data?.images || ""}
                    name="images"
                    type="file"
                    className="form-control"
                    id="customFile"
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
