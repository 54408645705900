//IMPORT REACT
import React, { useState, useCallback } from "react";
import axios from "../../api";
import { validateData, validateNumber, validateEditor } from "../../util";
import { ToastContainer, toast } from "react-toastify";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function CardAddCategory({ fetch }) {
  //SET STATES
  const [data, setData] = useState();
  const [isTrending, setTrending] = useState(false);
  const [validateErr, setValidateErr] = useState({});
  const [editordata, setEditorData] = useState();
  const [disable, setDisable] = useState(false);
  const handleChange = (event) => {
    const { name, value } = event.target;
    validateData(name, value, validateErr, setValidateErr);
    setData({ [name]: value });
    setDisable(false);
  };

  const handleEditorChange = (editorState) => {
    validateErr.editor = null;
    setValidateErr({ ...validateErr });
    setEditorData(editorState);
    setDisable(false);
  };

  const handleFetch = useCallback(() => {
    fetch();
  }, [fetch]);

  //VALIDATION
  const isValid = (htmldata) => {
    let valid = true;
    var strippedHtml = htmldata.replace(/<[^>]+>/g, "");
    if (
      validateData("title", data?.title, validateErr, setValidateErr) === false
    ) {
      valid = false;
    }
    if (
      validateEditor("editor", strippedHtml, validateErr, setValidateErr) ===
      false
    ) {
      valid = false;
    }
    return valid;
  };

  const setEditorReference = useCallback((ref) => {
    ref?.focus();
  }, []);

  //CLEAN ALL FIELDS WHEN SUBMIT
  const cleanAllField = () => {
    setData();
    setTrending(false);
    setEditorData();
  };

  //API CALL
  const handleSubmit = async (e) => {
    e.preventDefault();
    let htmldata = draftToHtml(convertToRaw(editordata.getCurrentContent()));
    let validateData = isValid(htmldata);
    if (validateData) {
      try {
        setDisable(true);
        const title = data.title;
        const res = await axios.post(`category/create`, {
          title: title,
          description: htmldata,
          ismain: isTrending,
        });
        if (res.status === 201) {
          toast.success("Data added successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          cleanAllField();
          return handleFetch();
        } else if (!res?.data?.id) {
          setDisable(true);
          toast.error("Duplicate Category name should not be added", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        setDisable(false);
        toast.error("Data Not Submitted", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Add Category
            </h6>
          </div>
        </div>

        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Title
                  </label>
                  <input
                    maxLength="155"
                    value={data?.title || ""}
                    name="title"
                    onChange={handleChange}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
                {validateErr && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {validateErr?.title}
                  </small>
                )}
              </div>
              <div className="w-full lg:w-6/12 px-4"></div>

              <div className="text-center flex justify-between">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  &nbsp; &nbsp; &nbsp;Description
                </label>
              </div>

              <div
                className="w-full lg:w-full px-4"
                style={{
                  height: "200px",
                  width: "100%",
                  marginTop: "8px",
                  padding: "1px",
                  border: "solid",
                  borderWidth: "0.2px",
                  borderColor: "grey",
                }}
              >
                <Editor
                  style={{ backgroundColor: "pink" }}
                  stripPastedStyles={true}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  editorState={editordata}
                  onEditorStateChange={handleEditorChange}
                  editorRef={setEditorReference}
                />
              </div>
              {validateErr && (
                <small
                  style={{ color: "red", fontSize: "14px", marginLeft: "24px" }}
                >
                  {validateErr?.editor}
                </small>
              )}
            </div>

            <button
              disabled={disable}
              type="submit"
              className="bg-lightBlue-500 mt-4 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-3 ease-linear transition-all duration-150"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
