//IMPORT REACT
import React, { useState, useCallback } from "react";
import axios from "../../api";
import { validateData } from "../../util";
import { ToastContainer, toast } from "react-toastify";

import {
  checkString,
  checkImageType,
  valueCapitalizer,
} from "util/inputhelper";

export default function CardBlog({ fetch }) {
  //SET STATES
  const [data, setData] = useState({});
  const [ismain, setIsmain] = useState(false);
  const [files, setFiles] = useState();
  const [validateErr, setValidateErr] = useState({});
  const [disable, setDisable] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateData(name, value, validateErr, setValidateErr);
    setData({ ...data, [name]: value });
    setDisable(false);
  };

  const handleFileUpload = (e) => {
    const files = e.target.files[0];
    const { validimage, errMsg } = checkImageType(files);
    setDisable(false);
    if (validimage) {
      setValidateErr({ ...validateErr, files: null });
      return setFiles(files);
    } else {
      validateErr.files = errMsg;
      setValidateErr({ ...validateErr });
      toast.error("File Not Valid", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    e.target.value = "";
    return setFiles();
  };

  const handleFetch = useCallback(() => {
    fetch();
  }, [fetch]);

  const isValid = () => {
    let valid = true;
    if (
      validateData(
        "description",
        data?.description,
        validateErr,
        setValidateErr
      ) === false
    ) {
      valid = false;
    }
    if (
      validateData("name", data?.name, validateErr, setValidateErr) === false
    ) {
      valid = false;
    }
    if (
      validateData("type", data?.type, validateErr, setValidateErr) === false
    ) {
      valid = false;
    }
    if (files === undefined || files?.images === "") {
      valid = false;
      validateErr.files = "This field is required";
      setValidateErr({ ...validateErr });
    }
    return valid;
  };

  const handleBlur = () => {
    let name = data?.name || "";
    let newvalue = valueCapitalizer(name).trim();
    setData({ ...data, name: newvalue });
    setDisable(false);
  };

  const cleanAllField = () => {
    setData({});
    setIsmain(false);
    setFiles({ images: "" });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validateData = isValid(data);
    const { validstring, errMsg } = checkString(data?.name);
    if (validateData && validstring) {
      try {
        setDisable(true);
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("type", data.type);
        formData.append("description", data.description);
        formData.append("photos", files);
        formData.append("createdBy", data.createdby ? data.createdby : "");
        ismain && formData.append("isMain", ismain);
        const res = await axios.post(`blog/create`, formData);
        if (res.status === 201) {
          toast.success("Data added successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          cleanAllField();
          return handleFetch();
        }
      } catch (err) {
        setDisable(false);
        toast.error("Data Not Submitted", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    if (!validstring) {
      validateErr.name = errMsg;
      setValidateErr({ ...validateErr });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">Add Blog</h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Name
                  </label>
                  <input
                    maxLength="55"
                    value={data?.name || ""}
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
                {validateErr && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {validateErr?.name}
                  </small>
                )}
              </div>
              <div className="w-full lg:w-6/12 px-2">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Type
                </label>

                <div className="relative w-full mb-3">
                  <select
                    onChange={handleChange}
                    name="type"
                    className="border-0 px-2 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={data?.type || "SELECT"}
                  >
                    <option>Select</option>
                    <option value="CONTINENT">Continent</option>
                    <option value="COUNTRY">Country</option>
                    {/* <option value="STATE">State</option> */}
                    <option value="CITY">State/City/Activity</option>
                  </select>
                </div>
                {validateErr && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {validateErr?.type}
                  </small>
                )}
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    createdby
                  </label>
                  <input
                    maxLength="55"
                    value={data?.createdby || ""}
                    // onBlur={handleBlur}
                    name="createdby"
                    onChange={handleChange}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <div className="form-check">
                    <input
                      value={data?.ismain || ""}
                      name="isMain"
                      onChange={(e) => setIsmain(!ismain)}
                      className="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      checked={ismain}
                    />
                    <label
                      className=" uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="flexCheckDefault"
                    >
                      &nbsp; Is Main
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Images
                  </label>
                  <input
                    onChange={(e) => handleFileUpload(e)}
                    name="images"
                    type="file"
                    className="form-control"
                    id="customFile"
                    value={files?.images}
                  />
                  <br />
                  <label style={{ color: "grey" }}>
                    (png(s),jpg(s),jpeg(s),jfif(s) are allowed)
                  </label>
                </div>
                {validateErr && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {validateErr?.files}
                  </small>
                )}
              </div>
              <div className="w-full lg:w-full px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Description
                  </label>
                  <textarea
                    maxLength="150"
                    style={{ resize: "none" }}
                    onChange={handleChange}
                    value={data?.description || ""}
                    placeholder="Description.."
                    name="description"
                    type="text-area"
                    className="form-control max-w-full"
                    rows="4"
                    cols="100"
                    id="customFile"
                  ></textarea>
                </div>
                {validateErr && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {validateErr?.description}
                  </small>
                )}
              </div>
            </div>
            <button
              disabled={disable}
              type="submit"
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
