//IMPORT REACT
import React, { useState, useCallback } from "react";
import axios from "../../api";
import { ToastContainer, toast } from "react-toastify";
// components

export default function CardState({ locations, fetch }) {
  //SET STATES
  const [data, setData] = useState();
  const [isTrending, setTrending] = useState(false);
  const [files, setFiles] = useState();
  const handleChange = (event) => {
    const { name, value } = event.target;

    setData({ ...data, [name]: value });
  };
  const handleFileUpload = (e) => {
    const files = e.target.files[0];
    return setFiles(files);
  };
  const handleFetch = useCallback(() => {
    fetch();
  }, [fetch]);
  //API CALL
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("photos", files);
      formData.append("trending", isTrending);
      formData.append("parentId", data.country);
      formData.append("latitude", data.latitude);
      formData.append("longitude", data.longitude);
      formData.append("position", 1);
      formData.append("type", "STATE");
      const res = await axios.post(`location/create`, formData);

      if (res.status === 201) {
        toast.success("Data Sent Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return handleFetch();
      }
    } catch (err) {
      toast.error("Data Not Submitted", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">Add State</h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-2">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Country name
                </label>

                <div className="relative w-full mb-3">
                  <select
                    onChange={handleChange}
                    name="country"
                    className="border-0 px-2 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  >
                    <option>select</option>
                    {locations?.map((location) => (
                      <option value={location.id || ""} key={location.id}>
                        {location.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    state name
                  </label>
                  <input
                    value={data?.name || ""}
                    name="name"
                    onChange={handleChange}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Latitude
                  </label>
                  <input
                    value={data?.latitude || ""}
                    name="latitude"
                    onChange={handleChange}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    longitude
                  </label>
                  <input
                    value={data?.longitude || ""}
                    name="longitude"
                    onChange={handleChange}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <div className="form-check">
                    <input
                      value={data?.trending || ""}
                      name="trending"
                      onChange={(e) => setTrending(e.target.checked)}
                      className="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                    />

                    <label
                      className=" uppercase text-blueGray-600 text-xs font-bold mb-2"
                      for="flexCheckDefault"
                    >
                      &nbsp; Trending
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Images
                  </label>
                  <input
                    onChange={(e) => handleFileUpload(e)}
                    value={data?.images || ""}
                    name="images"
                    type="file"
                    className="form-control"
                    id="customFile"
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
