//IMPORT REACT
import React from "react";
import { Route } from "react-router-dom";

const Protectedroute = ({ admin: Admin, auth: Auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("login") ? (
          <Admin {...props} />
        ) : (
          <Auth {...props} />
        )
      }
    />
  );
};

export default Protectedroute;
