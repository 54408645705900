//IMPORT AXIOS
import axios from "../api";
//API CALLS
export async function getLocations(locationType) {
  try {
    const { data } = await axios.get("/locations?type=" + locationType);
    return data;
  } catch (err) {
    console.log(err);
  }
}
export async function fetchBlogs() {
  try {
    const { data } = await axios.get("/blogs");
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function deleteLocation(id) {
  try {
    const { data } = await axios.delete("/location", {
      data: {
        id,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function deleteBlog(id) {
  try {
    const { data } = await axios.delete("/blog", {
      data: {
        id,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
  }
}
export async function deleteVaccine(id) {
  try {
    const { data } = await axios.delete("/vaccine", {
      data: {
        id,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
  }
}
export async function deleteCategory(id) {
  try {
    const { data } = await axios.delete("/category", {
      data: {
        id,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
  }
}
export async function deleteCategoryVaccine(id) {
  try {
    const { data } = await axios.delete("/categoryVaccine", {
      data: {
        categoryId: id,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function updateLocation(id) {
  try {
    const { data } = await axios.delete(`/location/${id}`);
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const validateData = (name, value, err, setErr) => {
  let isValidate = true;
  if (!value) {
    err[name] = "This field is required";
    isValidate = false;
  } else if (value && value.length === 0) {
    err[name] = "This field is required";
    isValidate = false;
  } else if (value && value.trim().length === 0) {
    err[name] = "Invalid Data";
    isValidate = false;
  } else {
    err[name] = "";
    isValidate = true;
  }
  setErr({ ...err });
  return isValidate;
};

export const validateEditor = (name, value, err, setErr) => {
  let isValidate = true;
  if (value && value.trim().length === 0) {
    err[name] = "This field is required";
    isValidate = false;
  } else {
    err[name] = "";
    isValidate = true;
  }
  setErr({ ...err });
  return isValidate;
};

export const validateNumber = (name, value, err, setErr) => {
  let isValidate = true;
  var format = /^[a-z A-Z]+$/;
  if (!format.test(value)) {
    err[name] = "This field should only accept characters";
    isValidate = false;
  }
  setErr({ ...err });
  return isValidate;
};

export const validateVaccine = (name, value, err, setErr) => {
  let isValidate = true;
  var format = /^[a-z A-Z.0-9-]+$/;
  if (!format.test(value)) {
    err[name] = "Special character not allowded";
    isValidate = false;
  }
  setErr({ ...err });
  return isValidate;
};

export const validatename = (name, value, err, setErr) => {
  let isValidate = true;
  var format = /^[a-z A-Z0-9]+$/;
  if (!format.test(value)) {
    err[name] = "Special character not allowded";
    isValidate = false;
  }
  setErr({ ...err });
  return isValidate;
};
export const validateEmail = (email) => {
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (re.test(email)) {
    return true;
  } else {
    return false;
  }
};

export async function getUserSummaries() {
  try {
    const { data } = await axios.get("/user/summaries");
    return data;
  } catch (err) {
    console.log(err);
  }
}
export async function getActivities() {
  try {
    const { data } = await axios.get("dashboardcount");
    return data;
  } catch (err) {
    console.log(err);
  }
}
export async function getVaccines() {
  try {
    const { data } = await axios.get("vaccines");
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function getCategory() {
  try {
    const { data } = await axios.get("activityCategoryVaccine");
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function getCategories() {
  try {
    const { data } = await axios.get("categories");
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function getCategoriesVaccine() {
  try {
    const { data } = await axios.get("activityVaccineList");
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function assingCategory(params) {
  try {
    const { data } = await axios.post("assignActivityCategoryVaccine", params);
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function getCategoryVaccineId(id) {
  try {
    const { data } = await axios.get(`/activityVaccine/${id}`);
    return data;
  } catch (err) {
    console.log(err);
  }
}
