import React, { useEffect, useState } from "react";

// components
import CardBlogs from "components/Cards/CardBlog";
import EditBlog from "components/Update/CardEditBlogs";
import CardTable from "components/Cards/CardTableNew";
import { fetchBlogs } from "../../util";

export default function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [editCard, setEditcard] = useState({});

  const handleEditCard = (row) => {
    setEditcard(row);
  };
  useEffect(() => {
    fetch();
  }, []);

  //GET BLOGS
  const fetch = async () => {
    const { totalCount, blogs } = await fetchBlogs();
    if (totalCount) return setBlogs(blogs);
  };

  return (
    <>
      <div className="flex flex-wrap">
        {editCard && editCard.id ? (
          <div className="w-full lg:w-12/12 px-4">
            <EditBlog
              fetch={fetch}
              blog={editCard}
              handleEditCard={handleEditCard}
              setEditcard={setEditcard}
            />
          </div>
        ) : (
          <div className="w-full lg:w-12/12 px-4">
            <CardBlogs fetch={fetch} />
          </div>
        )}
        <div className="w-full mb-12 px-4">
          <CardTable
            fetch={fetch}
            title="Blogs"
            handleEditCard={setEditcard}
            cols={["Name", "Type", "Description", "CreatedBy", "Image"]}
            data={blogs}
            isBlog={true}
          />
        </div>
      </div>
    </>
  );
}
