//IMPORT REACT
import React, { useState, useEffect } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const Gmap = React.memo(({ latitude, longitude, getLngLat, google }) => {
  const [cordinates, setCordinates] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    if (latitude && longitude) {
      return setCordinates({ lat: latitude, lng: longitude });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        const { latitude, longitude } = coords;
        setCordinates({ lat: latitude, lng: longitude });
      });
    }
  }, []);

  const onDragEnd = (coord) => {
    const { latLng } = coord;
    const Lat = latLng.lat().toString();
    const Lng = latLng.lng().toString();
    setCordinates({ lat: Lat, lng: Lng });
    getLngLat(Lat, Lng);
  };

  return (
    <>
      <label
        style={{ padding: "5px", textTransform: "capitalize", fontWeight: 500 }}
      >
        Drag and Drop the marker for getting latitude and longitude of location
      </label>
      <Map
        google={google}
        style={{ height: "500px" }}
        zoom={14}
        center={cordinates}
      >
        <Marker
          name={"Current Location"}
          position={cordinates}
          draggable
          onDragend={(e, map, coord) => {
            onDragEnd(coord);
          }}
        />
      </Map>
    </>
  );
});
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GMAP_API_KEY,
})(Gmap);
