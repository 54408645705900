export const Continents = [
  {
    name: "South East Asia",
    latitude: "27.449790329784268",
    longitude: "87.1875",
  },
  {
    name: "Africa",
    latitude: "15.721178178816588",
    longitude: "28.43967619375001",
  },
  {
    name: "South America",
    latitude: "-11.41780908893625",
    longitude: "-58.8109951929688",
  },
];
