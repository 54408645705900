//IMPORT REACT
import React, { useState, useCallback } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import axios from "../../api";

const Cardcategoryvaccine = ({ categories, fetch }) => {
  //SET STATES
  const [data, setData] = useState({});
  const [location, setLocation] = useState([]);
  const [validate, validateErr] = useState({});
  const [disable, setDisable] = useState(false);

  const handleChange = (event, values) => {
    if (values) {
      const { name } = event;
      validate[name] = false;
      validateErr(validate);
      return setData({ ...data, [name]: values });
    }
    const { name, value } = event.target;
    validate[name] = false;
    validateErr(validate);
    setData({ ...data, [name]: value });
    setDisable(false);
  };

  const handleFetch = useCallback(() => {
    fetch();
  }, [fetch]);

  const handleChecked = (event) => {
    const { value } = event.target;
    validate.location = false;
    setDisable(false);
    validateErr(validate);
    if (data.location && data.location[value]) {
      return setData({
        ...data,
        location: { ...data.location, [value]: false },
      });
    }
    setData({ ...data, location: { ...data.location, [value]: true } });
  };

  React.useEffect(() => {
    let vaccinesdata = categories?.vaccines?.map((item) => {
      let vaccineobj = {};
      vaccineobj.label = item.name;
      vaccineobj.id = item.id;
      vaccineobj.value = item.name;
      return vaccineobj;
    });
    setLocation(vaccinesdata);
  }, [categories]);

  const isValid = () => {
    let validcategory = true;
    let validlocation = true;
    if (!data?.category) {
      validcategory = false;
      validate.category = "field Is Required";
    }
    if (!data?.location) {
      validlocation = false;
      validate.location = "Please Select at Least One Location";
    } else {
      for (let key in data?.location) {
        if (data?.location[key]) {
          validlocation = true;
          validate.location = "";
          break;
        }
        validlocation = false;
        validate.location = "Please Select at Least One Location";
      }
    }
    validateErr({ ...validate });
    return [validlocation, validcategory];
  };

  const cleanAllField = () => {
    setData({});
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = isValid();
    const vaccineid = data?.vaccineid?.map((item) => item?.id);
    // data.vaccineid = vaccineid || [];
    // setData({...data})
    if (validate[0] && validate[1]) {
      try {
        setDisable(true);
        let formdata = data;
        formdata.vaccineid = vaccineid || [];
        const res = await axios.post("assignActivityCategoryVaccine", formdata);
        if (res.status === 201) {
          toast.success(res.data, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          cleanAllField();
          return handleFetch();
        } else if (!res?.data?.id) {
          setDisable(false);
          toast.error(res.data, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        toast.error("Data Not Submitted", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Add Category Vaccine
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="flex flex-wrap">
              <div className="w-full px-2">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Select Category
                </label>

                <div className="relative w-full mb-3">
                  <select
                    onChange={handleChange}
                    name="category"
                    className="border-0 px-2 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={data?.category || "Select"}
                  >
                    <option disabled>Select</option>
                    {categories?.categories?.map((category) => (
                      <option value={category.id || ""} key={category.id}>
                        {category.title}
                      </option>
                    ))}
                  </select>
                  {validate?.category && (
                    <small style={{ color: "red", fontSize: "14px" }}>
                      {validate?.category}
                    </small>
                  )}
                </div>
              </div>
              <div className="w-full px-2">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Select Vaccines
                </label>
                <div className="relative w-full mb-3">
                  <Select
                    isMulti
                    name="vaccineid"
                    value={data.vaccineid || ""}
                    options={location}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(value, event) => handleChange(event, value)}
                  />
                  {validate?.vaccineid && (
                    <small style={{ color: "red", fontSize: "14px" }}>
                      {validate?.vaccineid}
                    </small>
                  )}
                </div>

                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Select Locations
                </label>
                {validate?.location && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {validate?.location}
                  </small>
                )}
              </div>

              {categories?.locations?.map((item) => (
                <div
                  style={{ width: "20%" }}
                  className="px-2 inline-flex m-auto"
                  key={item.id}
                >
                  <input
                    checked={data.location && data.location[item.id]}
                    type="checkbox"
                    name="location"
                    onChange={handleChecked}
                    value={item.id || ""}
                    className="mr-2"
                  />

                  <label
                    style={{ wordBreak: "break-all" }}
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    {item.name}
                  </label>
                </div>
              ))}
            </div>
            <button
              disabled={disable}
              style={{ marginTop: "10px" }}
              type="submit"
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default Cardcategoryvaccine;
