//IMPORT REACT
import React, { useState, useCallback, useEffect, useRef } from "react";
import axios from "../../api";
import { validateData } from "../../util";
import { ToastContainer, toast } from "react-toastify";
import {
  checkString,
  checkImageType,
  valueCapitalizer,
} from "util/inputhelper";
// components

export default function EditBlog({ blog, fetch, handleEditCard, setEditcard }) {
  const [data, setData] = useState({});
  const [isUpdateMain, setUpdateIsmain] = useState(false);
  const [files, setFiles] = useState();
  const [showfiles, setShowfiles] = useState();
  const [validateErr, setValidateErr] = useState({});
  let formref = useRef(null);
  useEffect(() => {
    var elem = formref.current.getBoundingClientRect();
    window.scroll({
      top: window.scrollY + elem.y - formref.current.offsetTop,
      behavior: "smooth",
    });
  });
  useEffect(() => {
    if (blog.name) {
      data.name = blog.name;
      data.type = blog.type;
      data.description = blog.description;
      data.createdby = blog.createdBy;
      data.id = blog.id;
      setUpdateIsmain(blog.isMain);
      setData({ ...data });
      if (blog.photos && blog.photos.length > 0) {
        setShowfiles(
          process.env.REACT_APP_API + blog.photos[blog.photos.length - 1].name
        );
      }
    }
  }, [blog]);

  const isValid = () => {
    let valid = true;
    if (
      validateData(
        "description",
        data?.description,
        validateErr,
        setValidateErr
      ) === false
    ) {
      valid = false;
    }
    if (
      validateData("name", data?.name, validateErr, setValidateErr) === false
    ) {
      valid = false;
    }
    if (
      validateData("type", data?.type, validateErr, setValidateErr) === false
    ) {
      valid = false;
    }
    if (!files && !showfiles) {
      valid = false;
      validateErr.files = "This field is required";
      setValidateErr({ ...validateErr });
    }
    return valid;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateData(name, value, validateErr, setValidateErr);
    setData({ ...data, [name]: value });
  };

  const handleFileUpload = (e) => {
    const files = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(files);

    const { validimage, errMsg } = checkImageType(files);
    if (validimage) {
      setValidateErr({ ...validateErr, files: null });
      reader.addEventListener("load", function () {
        setShowfiles(this.result);
      });
      return setFiles(files);
    } else {
      validateErr.files = errMsg;
      setValidateErr({ ...validateErr });
      toast.error("File Not Valid", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    e.target.value = "";
    return setFiles();
  };

  const handleBlur = () => {
    let name = data?.name || "";
    let newvalue = valueCapitalizer(name).trim();
    setData({ ...data, name: newvalue });
  };

  const handleFetch = useCallback(() => {
    fetch();
  }, [fetch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validateData = isValid(data);
    const { validstring, errMsg } = checkString(data?.name);
    if (validateData && validstring) {
      try {
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("type", data.type);
        formData.append("description", data.description);
        formData.append("photos", files);
        formData.append("id", data.id);
        formData.append("isMain", isUpdateMain);
        formData.append("createdBy", data.createdby ? data.createdby : "");
        const res = await axios.post(`blog/create`, formData);
        if (res.status === 201) {
          toast.success("Data Updated Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setEditcard({});
          return handleFetch();
        }
      } catch (err) {
        toast.error("Failed To Update", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    if (!validstring) {
      validateErr.name = errMsg;
      setValidateErr({ ...validateErr });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">Edit blog</h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={(e) => handleSubmit(e)} ref={formref}>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Name
                  </label>
                  <input
                    maxLength="55"
                    value={data?.name || ""}
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
                {validateErr && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {validateErr?.name}
                  </small>
                )}
              </div>
              <div className="w-full lg:w-6/12 px-2">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Type
                </label>
                <div className="relative w-full mb-3">
                  <select
                    onChange={handleChange}
                    name="type"
                    className="border-0 px-2 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={data?.type || ""}
                  >
                    <option value="CONTINENT">Continent</option>
                    <option value="COUNTRY">Country</option>
                    <option value="CITY">State/City/Activity</option>
                  </select>
                </div>
                {validateErr && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {validateErr?.type}
                  </small>
                )}
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    createdby
                  </label>
                  <input
                    maxLength="55"
                    value={data?.createdby || ""}
                    name="createdby"
                    onChange={handleChange}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <div className="form-check">
                    <input
                      name="isMain"
                      onChange={(e) => setUpdateIsmain(e.target.checked)}
                      className="form-check-input"
                      type="checkbox"
                      id="flexUpdateCheckDefault"
                      checked={isUpdateMain}
                    />
                    <label
                      className=" uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="flexUpdateCheckDefault"
                    >
                      &nbsp; isMain
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <div>
                    <img
                      className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"
                      src={showfiles}
                      alt="..."
                      height="60"
                      width="60"
                    />
                  </div>
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Images
                  </label>
                  <input
                    onChange={(e) => handleFileUpload(e)}
                    name="images"
                    type="file"
                    className="form-control"
                    id="customFile"
                    style={{ color: "transparent" }}
                  />
                  <br />
                  <label style={{ color: "grey" }}>
                    (png jpg jpeg allowed)
                  </label>
                </div>
                {validateErr && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {validateErr?.files}
                  </small>
                )}
              </div>

              <div className="w-full lg:w-full px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Description
                  </label>
                  <textarea
                    style={{ resize: "none" }}
                    onChange={handleChange}
                    value={data?.description || ""}
                    placeholder="Description.."
                    name="description"
                    type="text-area"
                    className="form-control max-w-full"
                    rows="4"
                    cols="100"
                    id="customFile"
                  ></textarea>
                </div>
                {validateErr && (
                  <small style={{ color: "red", fontSize: "14px" }}>
                    {validateErr?.description}
                  </small>
                )}
              </div>
            </div>
            <button
              type="submit"
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            >
              Submit
            </button>
            <button
              className="bg-red-500 text-white active:bg-red-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
              onClick={(e) => {
                e.preventDefault();
                handleEditCard(false);
              }}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
