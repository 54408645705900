//IMPORT REACT
import React from "react";
import { withRouter, Link } from "react-router-dom";

const Navbar = ({ location }) => {
  const [path, setPath] = React.useState("");
  React.useEffect(() => {
    let { pathname } = location;
    let path = pathname.replace(/\/$/, "");
    let route = path.substring(path.lastIndexOf("/") + 1);
    if (route === "city") {
      return setPath("STATE/CITY/ACTIVITIES");
    }
    setPath(route);
  }, [location]);

  return (
    <>
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          <Link
            className="text-white text-sm uppercase hidden lg:inline-block font-semibold"
            to="/"
            onClick={(e) => e.preventDefault()}
          >
            {path}
          </Link>
        </div>
      </nav>
    </>
  );
};
export default withRouter(Navbar);
