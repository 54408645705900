//IMPORT REACT
import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "../../api";
import { ToastContainer, toast } from "react-toastify";
import { validateData, getCategoryVaccineId } from "../../util";
import Select from "react-select";
// components
export default function CardEditCategoryVaccine({
  fetch,
  handleEditCard,
  edititem,
  catvacdata,
}) {
  const [data, setData] = useState({});
  const [location, setLocation] = useState([]);
  const [vaccine, setVaccine] = useState([]);
  const [validate, validateErr] = useState({});
  const [categories, setCategories] = useState([]);
  let formref = useRef(null);

  useEffect(() => {
    var elem = formref.current.getBoundingClientRect();
    window.scroll({
      top: window.scrollY + elem.y - formref.current.offsetTop,
      behavior: "smooth",
    });
  });

  const getEditCategories = async (id) => {
    try {
      const { result } = await getCategoryVaccineId(id);
      return result;
    } catch (err) {
      console.log(err);
    }
  };

  const getVaccines = useCallback(
    (vac) => {
      let vaccinesdata = catvacdata?.vaccines?.map((item) => {
        let vaccineobj = {};
        vaccineobj.label = item.name;
        vaccineobj.id = item.id;
        vaccineobj.value = item.name;
        return vaccineobj;
      });
      return vaccinesdata;
    },
    [catvacdata]
  );

  useEffect(() => {
    setLocation(catvacdata.locations);
    setCategories(catvacdata.categories);
    setVaccine(getVaccines);
  }, [catvacdata.categories, catvacdata.locations, getVaccines]);

  useEffect(async () => {
    let result = await getEditCategories(edititem.id);
    data.category = edititem.id;
    let newlocation = catvacdata?.locations?.filter((item) =>
      result[0]?.location?.includes(item.id)
    );
    let newvaccines = catvacdata?.vaccines?.filter((item) =>
      result[0]?.vaccine?.includes(item.id)
    );
    let locationid = {};
    let selectedVaccine = newvaccines.map((item) => {
      let vac = {};
      vac.label = item.name;
      vac.id = item.id;
      vac.value = item.name;
      return vac;
    });
    newlocation.map((item) => {
      return (locationid[item.id] = true);
    });
    data.location = locationid;
    data.vaccineid = selectedVaccine;
    setData({ ...data });
  }, [edititem]);

  const handleChecked = (event) => {
    const { value } = event.target;
    validate.location = false;
    validateErr({ ...validate });
    if (data.location && data.location[value]) {
      return setData({
        ...data,
        location: { ...data.location, [value]: false },
      });
    }
    setData({ ...data, location: { ...(data.location || ""), [value]: true } });
  };
  const handleChange = (event) => {
    if (event.action === "remove-value") {
      const { name, removedValue } = event;
      let vaccinedata = data.vaccineid.filter(
        (item) => item.id !== removedValue.id
      );
      return setData({ ...data, [name]: vaccinedata });
    }
    if (event.action === "select-option") {
      const { name, option } = event;
      let vaccinedata = [...data.vaccineid, option];
      return setData({ ...data, [name]: vaccinedata });
    }
    if (!event.target) {
      data.vaccineid = [];
      return setData({ ...data });
    }
    const { name, value } = event.target;
    validateData(name, value, validate, validateErr);
    setData({ ...data, [name]: value });
  };
  const isValid = () => {
    let valid = true;
    for (let key in data?.location) {
      valid = data?.location[key];
      if (valid) {
        valid = true;
        validate.location = "";
        break;
      }
      valid = false;
      validate.location = "Please Select at Least One Location";
    }
    validateErr({ ...validate });
    return valid;
  };
  const handleFetch = useCallback(() => {
    fetch();
  }, [fetch]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = isValid();
    const vaccineid = data?.vaccineid?.map((item) => item?.id);
    let payload = {
      vaccineid,
      location: data.location,
      category: data.category,
      editid: data.category,
    };
    if (validate) {
      try {
        const res = await axios.post("assignActivityCategoryVaccine", payload);
        if (res.status === 201) {
          toast.success(res.data, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          handleEditCard(false);
          return handleFetch();
        } else if (!res?.data?.id) {
          toast.error(res.data, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        toast.error("Data Not Submitted", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              {" "}
              Edit Category Vaccine
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={(e) => handleSubmit(e)} ref={formref}>
            <div className="flex flex-wrap">
              <div className="w-full px-2">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Select Category
                </label>
                <div className="relative w-full mb-3">
                  <select
                    onChange={handleChange}
                    name="category"
                    className="border-0 px-2 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={data?.category || "Select"}
                    disabled={true}
                  >
                    <option disabled>Select</option>
                    {categories?.map((category) => (
                      <option value={category.id || ""} key={category.id || ""}>
                        {category.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="w-full px-2">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Select Vaccines
                </label>
                <div className="relative w-full mb-3">
                  <Select
                    isMulti
                    name="vaccineid"
                    value={data.vaccineid || ""}
                    options={vaccine}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(value, event) => handleChange(event)}
                  />
                </div>

                <div>
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Select Location
                  </label>
                  {validate?.location && (
                    <small style={{ color: "red", fontSize: "14px" }}>
                      {validate.location}
                    </small>
                  )}
                </div>
              </div>
              {location?.map((item) => (
                <div
                  style={{ width: "20%" }}
                  className="px-2 inline-flex m-auto"
                  key={item.id}
                >
                  <input
                    checked={data.location && data.location[item.id]}
                    type="checkbox"
                    name="location"
                    onChange={handleChecked}
                    value={item.id || ""}
                    className="mr-2"
                  />
                  <label
                    style={{ wordBreak: "break-all" }}
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    {item.name}
                  </label>
                </div>
              ))}
            </div>
            <button
              style={{ marginTop: "10px" }}
              type="submit"
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            >
              Submit
            </button>
            <button
              className="bg-red-500 text-white active:bg-red-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
              onClick={(e) => {
                e.preventDefault();
                handleEditCard(false);
              }}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
