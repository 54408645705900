import React, { useEffect, useState } from "react";
// components
import CardAddCity from "components/Cards/CardAddCity";
import EditCity from "components/Update/CardEditCity";
import CardTable from "components/Cards/CardTableNew";
import { getLocations } from "../../util";

export default function City() {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [editCard, setEditcard] = useState({});
  const [showmap, setShowMap] = useState(true);

  const handleEditCard = (row) => {
    setEditcard(row);
  };
  useEffect(() => {
    if (editCard.id) {
      setShowMap(false);
    } else {
      setShowMap(true);
    }
  }, [editCard]);

  useEffect(() => {
    fetch();
  }, []);

  //GET LOCATIONS
  const fetch = async () => {
    const states = await getLocations("country");
    if (states.locations) setStates(states.locations);
    const cities = await getLocations("city");
    if (cities.locations) return setCities(cities.locations);
  };

  return (
    <>
      <div className="flex flex-wrap">
        {editCard && editCard.id ? (
          <div className="w-full lg:w-12/12 px-4">
            <EditCity
              fetch={fetch}
              location={editCard}
              handleEditCard={handleEditCard}
              states={states}
              setEditcard={setEditcard}
            />
          </div>
        ) : (
          <div className="w-full lg:w-12/12 px-4">
            <CardAddCity fetch={fetch} locations={states} showmap={showmap} />
          </div>
        )}
        <div className="w-full mb-12 px-4">
          <CardTable
            fetch={fetch}
            title={"State/City/Activities"}
            handleEditCard={handleEditCard}
            cols={["Name", "Country", "Image", "description"]}
            // cols={["Name", "Longitude", "Latitude", "Image"]}
            data={cities}
            isCity={true}
          />
        </div>
      </div>
    </>
  );
}
