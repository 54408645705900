import React, { useEffect, useState } from "react";
// components
import CardTable from "components/Cards/CardTableUsersData";
import { getUserSummaries } from "../../util";

export default function Users() {
  const [summariesData, setSummariesData] = useState([]);
  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const { summaries } = await getUserSummaries();
    if (summaries) setSummariesData(summaries);
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full mb-12 px-4">
          <CardTable
            title={"Users"}
            cols={["Name", "Email", "Country", "Destinations", "Age", "Gender"]}
            data={summariesData}
          />
        </div>
      </div>
    </>
  );
}
