//IMPORT REACT
import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "../../api";
import { ToastContainer, toast } from "react-toastify";
import { validateData } from "../../util";
// components

export default function CardEditVaccine({ vaccine, fetch, handleEditCard }) {
  const [data, setData] = useState({});
  const [validateErr, setValidateErr] = useState({});
  let formref = useRef(null);

  useEffect(() => {
    var elem = formref.current.getBoundingClientRect();
    window.scroll({
      top: window.scrollY + elem.y - formref.current.offsetTop,
      behavior: "smooth",
    });
  });

  useEffect(() => {
    if (vaccine.name) {
      data.name = vaccine.name;
      data.dose = vaccine.dose;
      data.id = vaccine.id;
      setData({ ...data });
    }
  }, [vaccine]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateData(name, value, validateErr, setValidateErr);
    setData({ ...data, [name]: value });
  };
  const isValid = () => {
    let valid = true;
    if (
      validateData("name", data?.name, validateErr, setValidateErr) === false
    ) {
      valid = false;
    }
    return valid;
  };

  const handleFetch = useCallback(() => {
    fetch();
  }, [fetch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validateData = isValid(data);
    if (validateData) {
      try {
        const res = await axios.post(`vaccine/create`, data);
        if (res.status === 201) {
          toast.success("Data Updated Successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          handleEditCard(false);
          return handleFetch();
        }
      } catch (err) {
        toast.error("Failed To Update", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Edit Vaccine
            </h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={(e) => handleSubmit(e)} ref={formref}>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Name
                  </label>
                  <input
                    maxLength="55"
                    value={data?.name || ""}
                    name="name"
                    onChange={handleChange}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                  {validateErr && (
                    <small style={{ color: "red", fontSize: "14px" }}>
                      {validateErr?.name}
                    </small>
                  )}
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Dose
                  </label>
                  <input
                    maxLength="55"
                    value={data?.dose || ""}
                    name="dose"
                    onChange={handleChange}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            >
              Submit
            </button>
            <button
              className="bg-red-500 text-white active:bg-red-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
              onClick={(e) => {
                e.preventDefault();
                handleEditCard(false);
              }}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
