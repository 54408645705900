import React, { useEffect, useState } from "react";
// components
import { getVaccines } from "../../util/index";
import CardAddVaccine from "components/Cards/CardAddVaccine";
import CardEditVaccine from "components/Update/CardEditVaccine";
import CardTable from "components/Cards/CardTableNew";

export default function Vaccine() {
  const [editCard, setEditcard] = useState({});
  const [vaccines, setVaccine] = useState();

  const handleEditCard = (row) => {
    setEditcard(row);
  };

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const vaccine = await getVaccines();
    if (vaccine.vaccines) setVaccine(vaccine.vaccines);
  };
  return (
    <>
      <div className="flex flex-wrap">
        {editCard && editCard.id ? (
          <div className="w-full lg:w-12/12 px-4">
            <CardEditVaccine
              fetch={fetch}
              vaccine={editCard}
              handleEditCard={handleEditCard}
            />
          </div>
        ) : (
          <div className="w-full lg:w-12/12 px-4">
            <CardAddVaccine fetch={fetch} />
          </div>
        )}
        <div className="w-full mb-12 px-4">
          <CardTable
            fetch={fetch}
            title="Vaccine"
            handleEditCard={setEditcard}
            cols={["Name", "Dose"]}
            data={vaccines}
            isVaccine={true}
          />
        </div>
      </div>
    </>
  );
}
